import styled from "styled-components";

import { breakpoint } from "src/utils/media-query";

export const TwoColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;

  ${breakpoint("tabletLL")} {
    flex-basis: calc(50% - 1rem);

    &:first-of-type {
      margin-right: 2rem;
    }
  }
`;