import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { TwoColumnLayout, Column } from "src/components/layout/TwoColumnLayout";
import { PageLayout } from "src/components/layout/PageLayout";
import { Link } from "src/components/Link";
import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata } from "src/utils/image";
import { BodyText } from "src/components/BodyText";

interface ContactPageProps extends PageProps {
  data: GatsbyTypes.ContactPageQuery;
}

type OpeningHours = ReadonlyArray<GatsbyTypes.Maybe<Pick<GatsbyTypes.StrapiContactOpening_hours, "day" | "opens" | "closes">>>;

const ContactPage: React.FC<ContactPageProps> = ({ data }) => {
  const [ contactImage, contactImageAlt ] = getImageMetadata(data.strapiContact?.contact_image);

  const {
    contact_intro: introText,
    contact_phone: phoneNumber,
    contact_email: emailAddress,
    contact_google_maps_url: mapsUrl
  } = data.strapiContact || {};

  const {
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_city: city,
    address_postcode: postCode
  } = data.strapiContact?.address || {};

  const buildOpeningHours = React.useCallback((openingHours: OpeningHours) => {
    const trimTime = (time: string) => time.slice(0, 5);

    return (
      <FlexWrapper>
        <Text as="span" style={{ marginBottom: "0.5rem" }}>
          Opening hours
        </Text>
        {openingHours.map(info => {
          if (!info?.day) {
            return null;
          }

          if (!info?.opens || !info?.closes) {
            return (
              <Text as="span" key={info.day}>
                {info.day}
              </Text>
            );
          }

          return (
            <Text as="span" key={info.day}>
              {`${info.day} ${trimTime(info.opens)}–${trimTime(info.closes)}`}
            </Text>
          );
        })}
      </FlexWrapper>
    );
  }, []);

  return (
    <PageLayout title="Contact" width="wide">
      <StyledTwoColumnLayout>
        {contactImage && (
          <Column>
            <OutsideBorderBox>
              <InsideBorderBox>
                <GatsbyImage image={contactImage} alt={contactImageAlt} />
              </InsideBorderBox>
            </OutsideBorderBox>
            <ContentWrapper>
              <ContactInfoWrapper>
                <FlexWrapper>
                  <Text as="span">
                    {addressLine1}
                  </Text>
                  <Text as="span">
                    {addressLine2}
                  </Text>
                  <Text as="span">
                    {`${city} ${postCode}`}
                  </Text>
                </FlexWrapper>
              </ContactInfoWrapper>
              <ContactInfoWrapper>
                <Link href={mapsUrl} target="_blank" rel="noopener noreferrer">
                  Google map
                </Link>
              </ContactInfoWrapper>
              {(data.strapiContact?.opening_hours && data.strapiContact.opening_hours.length > 0) && (
                <ContactInfoWrapper>
                  {buildOpeningHours(data.strapiContact.opening_hours)}
                </ContactInfoWrapper>
              )}
            </ContentWrapper>
          </Column>
        )}
        <StyledColumn>
          {introText && (
            <ContactInfoWrapper style={{ marginTop: 0 }}>
              <FlexWrapper>
                <BodyText text={introText} />
              </FlexWrapper>
            </ContactInfoWrapper>
          )}
          <ContactInfoWrapper>
            <FlexWrapper>
              <EmailText>
                {"General enquiries: "}
                <Link href={`mailto:${emailAddress}`}>
                  {emailAddress}
                </Link>
              </EmailText>
            </FlexWrapper>
          </ContactInfoWrapper>
          <ContactInfoWrapper>
            <Link href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </Link>
          </ContactInfoWrapper>
          <ContactInfoWrapper>
            <FlexWrapper>
              <Text as="span">
                Follow us
              </Text>
              <SocialRow>
                {data.strapiContact?.instagram_url && (
                  <SocialLink href={data.strapiContact.instagram_url} target="_blank">
                    Instagram
                  </SocialLink>
                )}
                {data.strapiContact?.twitter_url && (
                  <SocialLink href={data.strapiContact.twitter_url} target="_blank">
                    Twitter
                  </SocialLink>
                )}
                {data.strapiContact?.facebook_url && (
                  <SocialLink href={data.strapiContact.facebook_url} target="_blank">
                    Facebook
                  </SocialLink>
                )}
              </SocialRow>
            </FlexWrapper>
          </ContactInfoWrapper>
        </StyledColumn>
      </StyledTwoColumnLayout>
    </PageLayout>
  );
};

export default ContactPage;

const StyledTwoColumnLayout = styled(TwoColumnLayout)`
  ${breakpoint("tabletLL")} {
    margin-top: 3rem;
  }
`;

const OutsideBorderBox = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.red};
  margin: 3rem 1rem;
  margin-bottom: 1rem;

  ${breakpoint("tabletSP")} {
    margin: 4rem 1rem;
    margin-bottom: 2rem;
  }
  ${breakpoint("tabletLL")} {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;

const InsideBorderBox = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.red};
  margin: 2px;
`;

const StyledColumn = styled(Column)`
  padding: 1rem;
  padding-top: 2rem;
  align-self: stretch;
  box-sizing: border-box;

  ${breakpoint("tabletLL")} {
    padding-top: 0;
    padding: 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContentWrapper = styled(FlexWrapper)`
  padding: 0 1rem;
  align-self: stretch;
  box-sizing: border-box;

  ${breakpoint("tabletLL")} {
    padding: 0;
  }
`;

const ContactInfoWrapper = styled.div`
  margin: 1rem 0;
`;

const EmailText = styled(Text)`
  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`;

const SocialLink = styled(Link)`
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
`;

export const query = graphql`
  query ContactPage {
    strapiContact {
      contact_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      contact_intro
      address {
        address_line_1
        address_line_2
        address_city
        address_postcode
      }
      contact_phone
      contact_email
      contact_google_maps_url
      opening_hours {
        day
        opens
        closes
      }
      instagram_url
      twitter_url
      facebook_url
      compliance
    }
  }
`;